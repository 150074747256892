import React from 'react';
import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';
import GoogleMapReact from 'google-map-react';

import StyledLink from './organisms/StyledLink';

const MOBILE_BREAKPOINT = '1000px';

const Container = styled.div`
    position: relative;
    z-index: 5;

    .brush-stroke {
        position: absolute;
        left: 0;
        right: 0;
        height: 140px;
        
        &--top {
            top: 0;
            transform: translateY(-30px);
        }

        &--bottom {
            bottom: 0;
            transform: translateY(50px);
        }
    }
`;

const Content = styled.div`
    position: relative;
    z-index: 5;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    padding: 100px 0 66px;
    font-style: normal;
    font-weight: normal;
    text-align: left;
    @media screen and (max-width: ${MOBILE_BREAKPOINT}) {
        padding: 50px 0 33px;
    }
`;

const TextContainer = styled.div`
    grid-column: 1;
    grid-row: 1;
    padding: 0 30px 0 120px;
    @media screen and (max-width: ${MOBILE_BREAKPOINT}) {
        grid-column: 1 / 3;
        grid-row: 1;
        padding: 0 30px;
        text-align: center
    }
`;

const MapContainer = styled.div`
    grid-column: 2;
    grid-row: 1 / 3;
    min-height: 560px;
    padding-right: 20px;

    @media screen and (max-width: ${MOBILE_BREAKPOINT}) {
        grid-column: 1 / 3;
        grid-row: 2;
        min-height: initial;
        height: 100vw;
        margin-bottom: 40px;
        padding: 0;
    }
`;

const Title = styled.h2`
    margin-bottom: 40px;
    font-family: 'Gloss-And-Bloom';
    font-size: 48px;
    line-height: 120%;
    letter-spacing: 0.2px;
    color: black;
`;

const Description = styled.div`
    margin-bottom: 40px;
    padding-right: 80px;
    font-family: 'D-DINExp';
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.3px;
    color: #6D6E71;

    @media screen and (max-width: ${MOBILE_BREAKPOINT}) {
        padding-right: 0;
    }
`;

const BtnContainer = styled.div`
    grid-column: 1;
    grid-row: 2;
    align-content: end;
    padding-left: 120px;
    text-align: left;
    @media screen and (max-width: ${MOBILE_BREAKPOINT}) {
        grid-column: 1 / 3;
        grid-row: 3;
        margin-bottom: 0;
        padding: 40px 0 30px;
        text-align: center;
        background-color: white;
    }
`;

const markerPosition = {
    position: 'absolute',
    transform: 'translate(-50%, -100%)'
}

const Mark = () => (
    <svg width="42" height="42" xmlns="http://www.w3.org/2000/svg" style={markerPosition}>
        <g>
            <path stroke="null" id="svg_2" fill="#e74c3c" d="m21.536638,0.484914c-7.602285,0 -13.765086,6.162801 -13.765086,13.765086c0,2.445023 0.656595,4.731748 1.77432,6.720803c0.185657,0.330362 0.380261,0.655562 0.591555,0.968718l11.399212,19.840651l11.399212,-19.840651c0.175505,-0.259816 0.326921,-0.535118 0.483499,-0.806978l0.1084,-0.16174c1.116693,-1.989055 1.773975,-4.27578 1.773975,-6.720803c0,-7.602285 -6.163317,-13.765086 -13.765086,-13.765086zm0,6.882543c3.800884,0 6.882543,3.081487 6.882543,6.882543c0,3.800884 -3.081659,6.882543 -6.882543,6.882543c-3.801056,0 -6.882543,-3.081659 -6.882543,-6.882543c0,-3.801056 3.081487,-6.882543 6.882543,-6.882543z"/>
            <path stroke="null" id="svg_3" fill="#c0392b" d="m21.482759,6.663794c-4.249223,0 -7.693965,3.458063 -7.693965,7.723717c0,4.265037 3.444742,7.723717 7.693965,7.723717c4.248608,0 7.693965,-3.45868 7.693965,-7.723717c0,-4.265654 -3.445358,-7.723717 -7.693965,-7.723717zm0,3.089487c2.54978,0 4.616379,2.074745 4.616379,4.63423s-2.066599,4.63423 -4.616379,4.63423s-4.616379,-2.074745 -4.616379,-4.63423s2.066599,-4.63423 4.616379,-4.63423z"/>
        </g>
    </svg>
);

const GoogleMap = ({coordinates}) => {
    const lat = coordinates.lat;
    const lng = coordinates.lng;

    const mapProps = {
        center: { lat, lng },
        zoom: 17,
    }, { center, zoom } = mapProps;

    return (
        <GoogleMapReact
          bootstrapURLKeys={{ key: process.env.GATSBY_GOOGLEMAP_KEY }}
          defaultCenter={center}
          center={center}
          defaultZoom={zoom}
        >
            <Mark lat={lat} lng={lng}/>
        </GoogleMapReact>
    );
}

const LocationsMapSection = ({title, description, coordinates}) => {
    return (
        <Container>
            <Content>
                <TextContainer>
                    <Title>{title}</Title>
                    <Description>
                        {description.map(paragraph => (
                            <p>{paragraph}</p>
                        ))}
                    </Description>
                </TextContainer>
                <MapContainer>
                    <GoogleMap coordinates={coordinates} />
                </MapContainer>
                <BtnContainer>
                    <StyledLink primary as="a" href={`https://www.google.com/maps/dir/?api=1&destination=${coordinates.lat},${coordinates.lng}`} target="_blank">get direction</StyledLink>
                </BtnContainer>
            </Content>
            <StaticImage src="../../static/svg/BrushStroke.svg" alt="" className="brush-stroke brush-stroke--top" />
            <StaticImage src="../../static/svg/BrushStroke.svg" alt="" className="brush-stroke brush-stroke--bottom" />
        </Container>
    )
};

export default LocationsMapSection;