import React from 'react';
import HomePageHero from '../components/HomePageHero';
import { graphql } from 'gatsby';
import { cities } from '../helpers';

import Review from '../components/Review';
import ContactUs from '../components/ContactUs';
import WhatSetsUsApart from '../components/templates/WhatSetsUsApart';
import FaqSection from '../components/FaqSection';
import LocationsMapSection from '../components/LocationsMapSection';
import SEO from '../components/SEO';

import Flowers from "../assets/images/category_images/flowers.png"
import Prerolls from "../assets/images/category_images/prerolls.png"
import Edibles from "../assets/images/category_images/edibles.png"
import Vapes from "../assets/images/category_images/vapes.png"
import Oils from "../assets/images/category_images/oils.png"
import Beverages from "../assets/images/category_images/beverages.png"

import StyledContainer from '../components/organisms/StyledContainer.js'
import CategorySection from '../components/templates/CategorySection.js'

import { getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from 'gatsby-background-image'

const categoriesDataA = [
    {
        src: Flowers,
        title: "Flowers",
        slug: "/livemenu?dtche[category]=flower",
    },
    {
        src: Prerolls,
        title: "Prerolls",
        slug: "/livemenu?dtche[category]=pre-rolls",
    },
    {
        src: Edibles,
        title: "Edibles",
        slug: "/livemenu?dtche[category]=edibles",
    },
    {
        src: Vapes,
        title: "Vapes",
        slug: "/livemenu?dtche[category]=vaporizers",
    },
    {
        src: Oils,
        title: "Oils",
        slug: "/livemenu?dtche[category]=concentrates",
    },
    {
        src: Beverages,
        title: "Beverages",
        slug: "/livemenu?dtche[subcategories]=drinks&dtche[category]=edibles",
    },
]

export default function London({data}){
    const imageprod = getImage(data.productbg);
    const bgImageProd = convertToBgImage(imageprod);
    
    const schema = {
        "@context": "https://schema.org",
        "@type": "Dispensary",
        "name": "Ronin Cannabis",
        "image": "/logo.jpg",
        "@id": "",
        "url": "https://ronincannabis.com/",
        "telephone": "(519)-714-0420",
        "address": {
            "@type": "PostalAddress",
            "streetAddress": "901 Jamieson Pkwy ",
            "addressLocality": "Cambridge",
            "addressRegion": "ON",
            "postalCode": "N3C 4N6",
            "addressCountry": "CA"
        },
        "geo": {
            "@type": "GeoCoordinates",
            "latitude": 43.423435163514014, 
            "longitude": -80.28735424599385
        },
        "openingHoursSpecification": [
            {
              "@type": "OpeningHoursSpecification",
              "closes":  "20:00:00",
              "dayOfWeek": "https://schema.org/Sunday",
              "opens":  "11:00:00"
            },
            {
              "@type": "OpeningHoursSpecification",
              "closes": "22:00:00" ,
              "dayOfWeek": "https://schema.org/Saturday",
              "opens": "10:00:00"
            },
            {
              "@type": "OpeningHoursSpecification",
              "closes":  "22:00:00",
              "dayOfWeek": "https://schema.org/Thursday",
              "opens": "10:00:00"
            },
            {
              "@type": "OpeningHoursSpecification",
              "closes": "22:00:00",
              "dayOfWeek": "https://schema.org/Tuesday",
              "opens": "10:00:00"
            },
            {
              "@type": "OpeningHoursSpecification",
              "closes": "22:00:00",
              "dayOfWeek":  "https://schema.org/Friday",
              "opens": "10:00:00"
            },
            {
              "@type": "OpeningHoursSpecification",
              "closes": "22:00:00",
              "dayOfWeek": "https://schema.org/Monday",
              "opens": "10:00:00"
            },
            {
              "@type": "OpeningHoursSpecification",
              "closes": "22:00:00",
              "dayOfWeek":  "https://schema.org/Wednesday",
              "opens": "10:00:00"
            }
          ]
    }

    return(
        <>
        <SEO
            description="With one of the highest numbers of retail stores per capita in Canada, 
            it can be incredibly challenging trying to find the right Cambridge retail store 
            to fulfill your cannabis needs."
            title="Cambridge Retail Store"
            schemaMarkup={schema}
        />
            <HomePageHero 
                title={"Cannabis Stores in Cambridge"}
                subtitleB={"Have You Been Trying to Find a Cambridge Retail Store You Can Trust?"}
                content={[`With one of the highest numbers of retail stores per capita in Canada, 
                it can be incredibly challenging trying to find the right Cambridge retail store 
                to fulfill your cannabis needs.`,

                `If your goal is to track down the best quality cannabis products like cannabis drinks,
                edibles, weed accessories and of course, cannabis buds, there's really only one option 
                - Ronin Cannabis.`,
                
                `Don't believe us? Come in and see for yourself why so many people are making Ronin their 
                first choice of all the Cambridge cannabis stores.`
                ]}
                buttontext={"shop now"}
                bannerImage={data.bannerImage}
                location={'cambridge'}
            />
            <BackgroundImage
                Tag="div"
                {...bgImageProd}
                preserveStackingContext
                style={{
                    width: '100%',
                    minHeight: '100vh'
                }}
            >
                <StyledContainer breakpoint="1200px">
                    <CategorySection
                        data={categoriesDataA}
                        columns='3'
                        subtitle="All the Cannabis Products You Could Ever Want"
                        title='The best weed products around'
                    />
                </StyledContainer>
            </BackgroundImage>
            {/* <Review reviewdata={data.reviews}/> */}
            <LocationsMapSection
                title={"The #1 Cannabis Store in Cambridge"}
                description={[
                    `With the huge amount of Cambridge retail stores available, it 
                    can feel like every one of them is the same, but that's simply not 
                    the case.`,

                    `Many retail stores are flying under the radar of the government, 
                    and choosing to provide products that aren't monitored or 
                    regulated under the cannabis safety guidelines.`,

                    `This means that those cannabis buds could put you at risk of 
                    getting sick, if they were grown using illegal pesticides. Or it 
                    could mean weed accessories like vape pens or vape cartridges 
                    that aren't safe to use or contain dangerous chemicals.`,

                    `We only provide the safest products for our customers, because 
                    we want everyone who visits our stores to know they can feel 
                    safe and secure in purchasing our products, without having to 
                    worry about their health or well-being.`,

                ]}
                coordinates={cities.Cambridge}
            />
            <ContactUs 
                hourstext={["OPEN 10AM - 10PM Monday - Saturday","11AM - 8PM Sunday"]} 
                address={"901 Jamieson Pkwy Cambridge, ON"}
                email={"info@ronincannabis.ca"}  
                phone={"(519)-783-7420"}
                //comingsoon
            />
            <WhatSetsUsApart 
                maintitle={"Knowledge & Expertise"}
                titleA={"Knowledge & Expertise"}
                contentA={`We take great pride in training our 
                staff of expert budtenders in everything that we can about each 
                and every product we carry. This way, when you have questions, 
                they've always got answers.`}
                titleB={"The Best Selection Around"}
                contentB={`You'll have a hard time finding a better selection 
                than what we can offer. Between our amazing array of weed accessories, 
                top-quality cannabis buds and our delicious edibles and cannabis drinks, 
                what more could you ask for?`}
                titleC={"Strict Quality Control"}
                contentC={`With only legal, regulated products for sale, you can rest assured 
                that every product you can purchase from Ronin Cannabis underwent a rigorous 
                quality control process, meant to keep the products top-quality, and to keep 
                you safe.`}
            />
            <FaqSection faqdata={data.faq}/>
        </>
    )
}

export const query = graphql`
    query{
        faq: allSanityFaq(filter: {page: {eq: "Cambridge Page"}}) {
            nodes {
                answer
                location
                question
            }
        }

        reviews: allSanityReview(filter: {location: {eq: "Cambridge"}}) {
            nodes {
                location
                review
                stars
                title
                customer
            }
        }

        bannerImage: file(relativePath: { eq: "cambridge-bg.jpg" }) {
            childImageSharp {
                gatsbyImageData(
                    width: 1900
                    quality:90
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                )
            }
        }

        productbg: file(relativePath: { eq: "productbg2.jpg" }) {
            childImageSharp {
                gatsbyImageData(
                    width: 1900
                    quality:90
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                )
            }
        }
    }
`;