import React from 'react';
import styled from 'styled-components';
import Faq from './Faq';

const FaqSecStyle = styled.section`
  h2 {
    color: black;
    text-align: center;
    padding: 0 15%;
  }

  max-width: 1100px;
  margin: 20px auto;
`;

export default function FaqSection({faqdata}){
    return(
        <FaqSecStyle>
            <h2>Frequently Asked Questions about Ronin Cannabis</h2>
            <Faq faqs={faqdata}/>
        </FaqSecStyle>
    );
}
