import * as React from "react"
import styled from 'styled-components'
import { StaticImage } from "gatsby-plugin-image"
 
import Background1 from '../../../static/backgrounds/what_sets_us_apart_1.png'
import Background2 from '../../../static/backgrounds/what_sets_us_apart_2.png'
import Background3 from '../../../static/backgrounds/what_sets_us_apart_3.png'
 
 
const MOBILE_BREAKPOINT = "768px";
 
const Container = styled.div`
    margin-top: 120px;
    margin-bottom: 40px;
    text-align: center;
`;
 
const Title = styled.h2`
    position: relative;
    z-index: 5;
    padding: 0 50px;
    font-family: 'Gloss-And-Bloom';
    font-style: normal;
    font-weight: normal;
    font-size: 48px;
    line-height: 120%;
    letter-spacing: 0.2px;
    color: black;
    
    @media screen and (max-width: ${MOBILE_BREAKPOINT}) {
        font-size: 24px;
    }
`;
 
const Content = styled.div`
    position: relative;
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
 
    .brush-stroke {
        position: absolute;
        left: 0;
        right: 0;
        height: 140px;
 
        &--top {
            top: 0;
            transform: translateY(-85px);
        }
    }
 
    @media screen and (max-width: ${MOBILE_BREAKPOINT}) {
        grid-template-columns: minmax(0, 1fr);
    }
`;
 
const Point = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 50% 20px 75px;
    text-align: center;
    color: white;
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%), url(${props => props.src});
    background-repeat: no-repeat;
    background-size: cover;
 
    @media screen and (max-width: ${MOBILE_BREAKPOINT}) {
        padding: 150px 0;
    }
`;
 
const PointTitle = styled.h3`
    margin-bottom: 40px;
    padding: 0 70px;
    font-family: 'D-DINExp';
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 108%;
    letter-spacing: 0.3px;

    @media screen and (max-width: 1200px) {
        padding: 0 20px;
    }
`;
 
const PointDescription = styled.div`
    margin-bottom: 30px;
    padding: 0 60px;
    font-family: 'D-DINExp';
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;

    @media screen and (max-width: 1200px) {
        padding: 0 20px;
    }
`;
 
export default function WhatSetsUsApart({titlemain, titleA, titleB, titleC, contentA, contentB, contentC}){
    return(
    <Container>
        <Title>What Sets Ronin Cannabis Apart?</Title>
        <Content>
            <Point src={Background1}>
                <PointTitle>Knowledge & Expertise</PointTitle>
                <PointDescription>
                    We take great pride in training our staff of 
                    expert budtenders in everything that we can 
                    about each and every product we carry. This 
                    way, when you have questions, they've always 
                    got answers.
                </PointDescription>
            </Point>
            <Point src={Background2}>
                <PointTitle>We Have New, Fresh Product Weekly</PointTitle>
                <PointDescription>
                    You'll have a hard time finding a better 
                    selection than what we can offer. Between 
                    our amazing array of weed accessories, 
                    top-quality cannabis buds and our delicious 
                    edibles and cannabis drinks, what more could 
                    you ask for?
                </PointDescription>
            </Point>
            <Point src={Background3}>
                <PointTitle>Conscious Consumption</PointTitle>
                <PointDescription>
                    With only legal, regulated products for sale, 
                    you can rest assured that every product you 
                    can purchase from Ronin Cannabis 
                    underwent a rigorous quality control process, 
                    meant to keep the products top-quality, and 
                    to keep you safe.
                </PointDescription>
            </Point>
            <StaticImage src='../../../static/svg/BrushStroke.svg' alt="" className="brush-stroke brush-stroke--top"/>
        </Content>
    </Container>
    )
}